import React from "react"
import { withWidth, Divider } from "@material-ui/core"

const ThemedDivider = props => {
  const { width } = props
  const isSmall = width === "xs" || width === "sm"
  return (
    <Divider
      style={{
        backgroundColor: "#ffffff38",
        width: "85%",
        marginLeft: isSmall ? "auto" : "auto",
        marginRight: isSmall ? "auto" : "auto",
        marginTop: isSmall ? 80 : 120,
        marginBottom: isSmall ? 80 : 120,
      }}
    />
  )
}

export default withWidth()(ThemedDivider)
