import { Box, ButtonBase, withWidth } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import ArrowForward from "@material-ui/icons/ArrowForward"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import RemoveIcon from "@material-ui/icons/Remove"
import classnames from "classnames"
import { Link, graphql } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"
import { useReactToPrint } from "react-to-print"
import InvestFooter from "../components/InvestFooter"
import Layout from "../components/Layout"
import LocalizedLink from "../components/LocalizedLink"
import SendEquiry from "../components/SendEquiry"
import ThemedDivider from "../components/ThemedDivider"
import SimulationInvest from "../images/SimulationInvest.png"
import "../styles/commonStyleSheet.scss"
import { SEO } from "../components/SEO"

export const query = graphql`
  query {
    allContentfulGoldenVisaSimulationCalculator(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          investmentOptions
          seoTitle
          seoDescription
          resultsJson {
            result
            initialValue
            availableForAll
            supportedInvestmentOptions
            fixedIncrement
            double
          }
        }
      }
    }
    allContentfulInvestPageModel {
      edges {
        node {
          querySectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          querySectionTopics
          querySectionDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulLandingPageModel {
      edges {
        node {
          hateContactFormTitle
          hateContactFormSubTitle
          hateContactFormLogos {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          hateContactFormLogosId
          queryDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
const useStyles = makeStyles(theme => ({
  media: {
    height: 376,
  },
  image: {
    height: 500,
    width: "100%",
  },
  cardimage: {
    maxHeight: 500,
    width: 600,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
    maxWidth: 500,
    backgroundColor: "black",
    marginTop: 100,
  },
  textWhite: {
    color: "white",
  },
  indicator: {
    backgroundColor: "white",
  },
  tabRoot: {
    "&:hover": {
      color: "white",
    },
    "&$tabSelected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "white",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
      color: "white",
    },
    margin: {
      margin: theme.spacing(1),
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    input: {
      color: "white",
      underline: {
        "&$before": {
          borderColor: "white",
        },
      },
    },
    menu: {
      color: "white",
    },
  },
  labelColor: {
    color: "white",
  },
  label: {
    "&$focusedLabel": {
      color: "white",
    },
    "&$erroredLabel": {
      color: "white",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  underline: {
    "&$error:after": {
      borderBottomColor: "white",
    },
    "&:after": {
      borderBottom: `1px solid #ffffff38`,
    },
  },
  input: {
    color: "white",
    borderBottom: `1px solid #ffffff38`,
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#FAD578",
      color: "#000",
    },
    transition: "all 0.3s ease",
  },
}))

const GoldenVisaSimulation = ({
  location,
  pageContext,
  width,
  data: {
    allContentfulGoldenVisaSimulationCalculator,
    allContentfulInvestPageModel,
    allContentfulLandingPageModel,
  },
}) => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)

  // print result state
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  })

  // page data
  const {
    investmentOptions,
    resultsJson,
    seoTitle,
    seoDescription,
  } = allContentfulGoldenVisaSimulationCalculator.edges[0].node
  const {
    querySectionTopics,
    querySectionImage,
    querySectionDescription,
  } = allContentfulInvestPageModel.edges[0].node
  const initialInvestmentOption = !!location?.state?.cardTitle
    ? location?.state?.cardTitle
    : investmentOptions[0]
  const {
    hateContactFormTitle,
    hateContactFormSubTitle,
    hateContactFormLogos,
    hateContactFormLogosId,
  } = allContentfulLandingPageModel.edges[0].node

  // styles
  const classes = useStyles()

  // calculator initial state
  const [initialResultValues, setInitialResultValues] = React.useState({})
  const [currentInvestmentOption, setCurrentInvestmentOption] = useState(
    initialInvestmentOption
  )
  const [currentResultValues, setCurrentResultValues] = React.useState({})

  // eslint-disable-next-line no-unused-vars
  const [currency, setCurrency] = React.useState("")
  const [dependantsValue, setDependantsValue] = useState({
    sim1: 0,
    sim2: 0,
    sim3: 0,
    sim4: 0,
    sim5: 0,
    sim6: 0,
  })
  const [total, setTotal] = React.useState(0)
  const isSmall = width === "xs" || width === "sm"
  const isMedium = width === "md"

  // on investment option change
  const handleChange = event => {
    let value = event.target.value
    if (currentInvestmentOption !== value) {
      setCurrentInvestmentOption(value)
      setDependantsValue({ sim1: 0, sim2: 0, sim3: 0, sim4: 0, sim5: 0 })
      setCurrentResultValues(initialResultValues)
      const updatedTotal = initialResultValues[value]
        .map(({ initialValue }) => initialValue)
        .reduce((prev, current) => prev + current)
      setTotal(updatedTotal)
    }
    setCurrency(value)
  }

  useEffect(() => {
    const results = {}
    const supportedIndexes = []

    // eslint-disable-next-line array-callback-return
    investmentOptions.map(investmentOption => {
      const availableResults = resultsJson
        .filter(({ availableForAll }) => availableForAll)
        .map(({ initialValue, result, fixedIncrement, double }) => {
          return { result, initialValue, fixedIncrement, double }
        })
      const supportedInvestmentOptions = resultsJson
        .filter(({ supportedInvestmentOptions }, index) => {
          if (!!supportedInvestmentOptions) {
            if (
              supportedInvestmentOptions.filter(
                supportedOption => supportedOption === investmentOption
              ).length > 0
            ) {
              supportedIndexes.push(index)
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })
        .map(({ result, initialValue, fixedIncrement, double }) => {
          return { result, initialValue, fixedIncrement, double }
        })
      results[investmentOption] = [
        ...availableResults,
        ...supportedInvestmentOptions,
      ]
    })
    const actualTotal = results[currentInvestmentOption]
      .map(({ initialValue }) => initialValue)
      .reduce((prev, current) => prev + current)
    setTotal(() => actualTotal)
    setCurrentResultValues(results)
    setInitialResultValues(results)
  }, [currentInvestmentOption, investmentOptions, resultsJson])

  const sumValues = dependantValue => {
    const totalDependents =
      Object.values(dependantValue).reduce((prev, current) => prev + current) +
      1
    let totalAmount = 0
    if (initialResultValues[currentInvestmentOption]?.length > 0) {
      const initialResult = initialResultValues[currentInvestmentOption].map(
        ({ result, initialValue, fixedIncrement, double }) => {
          const updatedInitialValue =
            fixedIncrement > 0
              ? fixedIncrement * (totalDependents - 1) + initialValue
              : double
              ? initialValue * totalDependents
              : initialValue
          totalAmount = totalAmount + +updatedInitialValue.toFixed(2)
          return {
            result,
            initialValue: +updatedInitialValue.toFixed(2),
            fixedIncrement,
            double,
          }
        }
      )
      setCurrentResultValues({
        ...currentResultValues,
        [currentInvestmentOption]: initialResult,
      })
      setTotal(() => totalAmount)
    }
  }

  return (
    <Layout
      backgroundcolor={"black"}
      Textcolor={"white"}
      selected={"invest"}
      button={"#FAD578"}
      location={location}
      pageContext={pageContext}
    >
      <SEO
        seoData={{
          title: seoTitle,
          description: seoDescription,
        }}
      />
      <div
        style={{
          backgroundColor: "black",
          overflowX: isSmall || isMedium ? "hidden" : "inherit",
        }}
        className={classnames(
          "golden-visa golden-visa-simulation-calculator",
          locale === "zh" && "chinese-version"
        )}
      >
        <div>
          {!isSmall && (
            <Grid item lg={3} xs={12} md={3} className="services-card">
              <Card className="card">
                <CardContent>
                  <Typography className="main-heading">Services</Typography>
                  <Typography className="sub-heading">Introduction</Typography>
                  {[
                    {
                      sectionTitle: "Business Representation",
                      href: "business-representation",
                    },
                    { sectionTitle: "Import & Export", href: "import-export" },
                    {
                      sectionTitle: "Connect Delegations",
                      href: "connect-delegations",
                    },
                    {
                      sectionTitle: "Real Estate",
                      href: "real-estate",
                    },
                    {
                      sectionTitle: "Business Broker",
                      href: "business-broker",
                    },
                  ].map(({ sectionTitle, href }) => {
                    return (
                      <Typography className="each-section-link">
                        <Link
                          to={`/InvestScreen#${href}`}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {" "}
                          {sectionTitle}
                        </Link>
                      </Typography>
                    )
                  })}
                  <div style={{ marginTop: "50%" }}>
                    <List
                      className={classes.root}
                      style={{ backgroundColor: "#1A1B1D" }}
                    >
                      {[
                        { link: translations?.goldenVisa, href: "/GoldenVisa" },
                        {
                          link: translations?.whyPortugal,
                          href: "/WhyPortugal",
                        },
                        // {
                        //   link: translations?.realEstateOpportunities,
                        //   href: "/RealEstateOpportunities",
                        // },
                        // {
                        //   link: translations?.investmentOpportunities,
                        //   href: "/InvestmentOpportunities",
                        // },
                      ].map(({ link, href }, index) => {
                        return (
                          <React.Fragment key={index}>
                            <ListItem role={undefined}>
                              <ListItemText id="abc">
                                <LocalizedLink
                                  style={{ textDecoration: "none" }}
                                  to={href}
                                >
                                  <Typography
                                    className={classes.textWhite}
                                    style={{
                                      fontSize: 16,
                                      color: index === 0 ? "#FAD578" : "white",
                                      fontFamily: "MabryProLight",
                                      textDecoration: "none",
                                    }}
                                  >
                                    {link}
                                  </Typography>
                                </LocalizedLink>
                              </ListItemText>
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="comments"
                                  style={{
                                    color: index === 0 ? "#FAD578" : "white",
                                  }}
                                >
                                  <div className={classes.arrow}>
                                    <ArrowForward />
                                  </div>
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            {index !== 3 && (
                              <Divider
                                style={{ backgroundColor: "#ffffff38" }}
                              />
                            )}
                          </React.Fragment>
                        )
                      })}
                    </List>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
          <div className="golden-visa-body">
            <Grid
              container
              style={{
                paddingLeft: isSmall ? 37 : 100,
                paddingRight: isSmall ? 0 : isMedium ? 203 : 60,
                paddingBottom: 60,
                paddingTop: 60,
              }}
            >
              <Grid item className="top-section">
                <Typography
                  variant="h4"
                  component="h4"
                  className={classes.textWhite}
                  style={{ fontFamily: "clearface", fontSize: 52 }}
                >
                  {translations?.goldenVisaSimulation}
                </Typography>

                <Grid container style={{ marginTop: 12 }}>
                  <Grid item lg={12} style={{ width: "100%" }}>
                    <div className="investment-options">
                      <Card className="investment-options-card">
                        <CardContent className="card-content">
                          <div>
                            <Typography
                              className={classes.textWhite}
                              variant="h5"
                              component="h5"
                            >
                              {translations?.investPages?.selectInvestment}
                            </Typography>
                            <Typography>
                              {
                                translations?.investPages
                                  ?.selectInvestmentDescription
                              }
                            </Typography>
                          </div>
                          <TextField
                            className="text-field"
                            id="standard-select-currency"
                            select
                            label="Topic"
                            value={currentInvestmentOption}
                            onChange={handleChange}
                            InputProps={{
                              className: classes.input,

                              classes: {
                                root: classes.underline,
                                error: classes.error,
                              },
                            }}
                            InputLabelProps={{
                              className: classes.labelColor,
                              classes: {
                                root: classes.label,
                                focused: classes.focusedLabel,
                                error: classes.erroredLabel,
                              },
                              shrink: true,
                              fontFamily: "MabryProLight",
                            }}
                            margin="normal"
                          >
                            {investmentOptions.map(
                              (investmentOption, index) => (
                                <MenuItem key={index} value={investmentOption}>
                                  {investmentOption}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </CardContent>
                      </Card>

                      <img src={SimulationInvest} alt="" />
                    </div>
                    <div className="household-information-section">
                      <Typography>
                        {translations?.investPages?.householdInformation}
                      </Typography>
                      <div className="household-information-grid">
                        <div className="spouse-box">
                          <div className="dependents-value">
                            <ButtonBase
                              onClick={async () => {
                                if (dependantsValue["sim6"] + 1 > 1) {
                                  await setDependantsValue({
                                    ...dependantsValue,
                                    sim6: dependantsValue["sim6"] - 1,
                                  })

                                  sumValues({
                                    ...dependantsValue,
                                    sim6: dependantsValue["sim6"] - 1,
                                  })
                                }
                              }}
                            >
                              <ChevronLeftIcon style={{ color: "white" }} />
                            </ButtonBase>
                            <Typography className="yes">
                              {dependantsValue["sim6"] > 0
                                ? translations?.investPages?.yes
                                : translations?.investPages?.no}
                            </Typography>
                            <ButtonBase
                              onClick={async () => {
                                if (dependantsValue["sim6"] + 1 < 2) {
                                  await setDependantsValue({
                                    ...dependantsValue,
                                    sim6: dependantsValue["sim6"] + 1,
                                  })
                                  sumValues({
                                    ...dependantsValue,
                                    sim6: dependantsValue["sim6"] + 1,
                                  })
                                }
                              }}
                            >
                              <ChevronRightIcon style={{ color: "white" }} />
                            </ButtonBase>
                          </div>
                          <Typography className="spouse">
                            {translations?.investPages?.spouse}
                          </Typography>
                        </div>

                        {[
                          {
                            dependentType: `<11 ${translations?.investPages?.years} ${translations?.investPages?.old}`,
                            simType: "sim1",
                          },
                          {
                            dependentType: `12-17 ${translations?.investPages?.years} ${translations?.investPages?.old}`,
                            simType: "sim2",
                          },
                          {
                            dependentType: `18-24 ${translations?.investPages?.years} ${translations?.investPages?.old}`,
                            simType: "sim3",
                          },
                          {
                            dependentType: `24-65 ${translations?.investPages?.years} ${translations?.investPages?.old}`,
                            simType: "sim4",
                          },
                          {
                            dependentType: `>65 ${translations?.investPages?.years} ${translations?.investPages?.old}`,
                            simType: "sim5",
                          },
                        ].map(({ dependentType, simType }) => {
                          return (
                            <div className="household-information">
                              <div className="household-information-main">
                                <div className="dependents">
                                  <div className="dependents-value">
                                    <ButtonBase
                                      onClick={async () => {
                                        await setDependantsValue({
                                          ...dependantsValue,
                                          [simType]:
                                            dependantsValue[simType] === 0
                                              ? 0
                                              : dependantsValue[simType] - 1,
                                        })
                                        // eslint-disable-next-line no-lone-blocks
                                        {
                                          dependantsValue[simType] > 0 &&
                                            sumValues({
                                              ...dependantsValue,
                                              [simType]:
                                                dependantsValue[simType] - 1,
                                            })
                                        }
                                      }}
                                    >
                                      <RemoveIcon style={{ color: "white" }} />
                                    </ButtonBase>
                                    <Typography>
                                      {dependantsValue[simType]}
                                    </Typography>
                                    <ButtonBase
                                      onClick={async () => {
                                        await setDependantsValue({
                                          ...dependantsValue,
                                          [simType]:
                                            dependantsValue[simType] + 1,
                                        })

                                        sumValues({
                                          ...dependantsValue,
                                          [simType]:
                                            dependantsValue[simType] + 1,
                                        })
                                      }}
                                    >
                                      <AddIcon style={{ color: "white" }} />
                                    </ButtonBase>
                                  </div>
                                  <div className="dependents-type">
                                    <Typography>
                                      {translations?.investPages?.dependents}
                                    </Typography>
                                    <Typography>{dependentType}</Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              conatiner
              style={{
                paddingLeft: isSmall ? 37 : 100,
                marginTop: isSmall || isMedium ? 20 : -35,
              }}
            >
              <Grid item lg={4}>
                <Typography
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "MabryProBold",
                    fontSize: 20,
                  }}
                >
                  {translations?.investPages?.results}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              ref={componentRef}
              container
              style={{
                paddingLeft: isSmall ? 37 : 100,
                paddingTop: 23,
              }}
            >
              {currentResultValues[currentInvestmentOption]?.length > 0 &&
                currentResultValues[currentInvestmentOption]?.map(
                  ({ result, initialValue }, index) => (
                    <Grid item xs={12} lg={8} key={index}>
                      <div className="results">
                        <div>{result}</div>
                        <div>
                          {/* {getPriceE(item.priceE, index)} */}
                          {initialValue} {translations?.eur}
                        </div>
                      </div>
                    </Grid>
                  )
                )}
              <Grid item xs={12} lg={8}>
                <div className="results-total">
                  <div
                    style={{
                      marginLeft: isSmall ? "50%" : "81%",
                    }}
                  >
                    TOTAL
                  </div>
                  <div>
                    {total} ${translations?.eur}
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                paddingLeft: isSmall ? 37 : 100,
                paddingTop: isSmall ? 0 : 56,
              }}
            >
              <Grid item md={4} className="pdf-card-main">
                <Card className="pdf-card">
                  <CardContent>
                    <Typography className="heading">
                      {translations?.investPages?.wantFullReport}
                    </Typography>
                    <Typography className={classes.textWhite}>
                      You can print your copy right now
                    </Typography>
                    <Button
                      onClick={handlePrint}
                      variant="contained"
                      className="btn"
                    >
                      {translations?.investPages?.printPdf}
                    </Button>
                  </CardContent>
                </Card>
                <div className="pdf-icon">
                  <img src={require("../images/pdf_icon.png")} alt="" />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                className="investment-options-description"
              >
                <Box>
                  <Typography>
                    <span style={{ color: "#FAD578" }}>*</span> IMT: 6,5%
                    (Prédios Urbanos para fins comerciais, terreno para
                    construção); 6% (Prédios Urbanos para fins de habitação); 5%
                    (Prédios Rústicos).
                  </Typography>
                  <Typography>
                    <span style={{ color: "#FAD578" }}>**</span> Imposto de
                    Selo: Taxa aplicável entre 0,3% e 0,5% (Prédio Urbano): 0,8%
                    (Prédios Rústicos). Imposto Municipal sobre Imóveis (“IMI”):
                    Incidência sobre o valor patrimonial tributário do imóvel,
                    pago anualmente, nos meses de Abril, Julho e Novembro.
                  </Typography>
                </Box>{" "}
              </Grid>
            </Grid>
            <ThemedDivider />
          </div>
        </div>

        <SendEquiry
          reverse
          image={querySectionImage}
          queryTopics={querySectionTopics}
          hateContactFormTitle={hateContactFormTitle}
          hateContactFormSubTitle={hateContactFormSubTitle}
          hateContactFormLogos={hateContactFormLogos}
          hateContactFormLogosId={hateContactFormLogosId}
          queryDescription={querySectionDescription?.childMarkdownRemark?.html}
        />
        <InvestFooter />
      </div>
    </Layout>
  )
}

export default withWidth()(GoldenVisaSimulation)
