import { Snackbar, withWidth } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import ArrowForward from "@material-ui/icons/ArrowForward"
import Alert from "@material-ui/lab/Alert"
import Axios from "axios"
import Img from "gatsby-image"
import React, { useState } from "react"
import ReactHtmlParser from "react-html-parser"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"
import { BASE_URL } from "../../config/api"
import ThemedDivider from "../ThemedDivider"
import Select from "@material-ui/core/Select"
import "./index.scss"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  card: {
    maxWidth: "424px",
  },
  media: {
    height: 376,
    width: 508,
  },
  image: {
    height: 500,
    width: 600,
  },
  cardimage: {
    maxHeight: 500,
    width: 600,
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    width: "100%",
    color: "gray",
  },
  margin: {
    margin: theme.spacing(1),
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    color: "white",
    underline: {
      "&$before": {
        borderColor: "grey",
      },
    },
    fontFamily: "MabryProRegular",
    borderBottom: `1px solid gray`,
  },
  menu: {
    color: "white",
  },
  labelColor: {
    color: "white",
    fontFamily: "MabryProLight",
  },
  label: {
    "&$focusedLabel": {
      color: "white",
    },
    "&$erroredLabel": {
      color: "white",
    },
  },
  focusedLabel: {},
  erroredLabel: {},
  underline: {
    "&$error:after": {
      borderBottomColor: "gray",
    },
    "&:after": {
      borderBottom: `2px solid gray`,
    },
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderLeft: "1px solid #fff",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#000",
    },
    transition: "all 0.3s ease",
  },
}))

const SendEquiry = ({
  queryTopics,
  reverse,
  width,
  image,
  hateContactFormTitle,
  hateContactFormSubTitle,
  hateContactFormLogos,
  hateContactFormLogosId,
  queryDescription,
}) => {
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const classes = useStyles()
  const isSmall = width === "xs" || width === "sm"
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email: "",
    message: "",
    fullName: "",
    topic: "",
  })
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [emailError, setEmailError] = useState("")

  const handleFormData = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const onSubmit = e => {
    e.preventDefault()
    console.log("formData", formData)
    console.log("isEmailValid", isEmailValid)
    if (
      !!formData.email &&
      !!formData.fullName &&
      !!formData.topic &&
      !!formData.message &&
      isEmailValid
    ) {
      setLoading(true)

      Axios.post(`${BASE_URL}/query`, {
        ...formData,
      })
        .then(response => {
          setLoading(false)
          setSuccess(response.data.message)
        })
        .catch(error => {
          setLoading(false)
          setError(error.message)
        })
    } else {
      setError("Please fill in the fields!")
    }
  }
  return (
    <form onSubmit={onSubmit} className="send-enquiry">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={success.length}
        autoHideDuration={6000}
        onClose={() => setSuccess("")}
      >
        <Alert onClose={() => setSuccess("")} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error.length}
        autoHideDuration={6000}
        onClose={() => setError("")}
      >
        <Alert onClose={() => setError("")} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <div
        style={
          isSmall
            ? { paddingLeft: 0, paddingRight: 0 }
            : {
                paddingLeft: 60,
                paddingRight: 150,
              }
        }
      >
        <Grid
          container
          spacing={4}
          justify="center"
          alignItems="center"
          direction={reverse ? "row-reverse" : "row"}
        >
          <Grid item xs={10} sm={11} md={5} className={classes.column}>
            <Typography
              variant="h4"
              component="h4"
              style={{
                color: "white",
                fontFamily: "clearface",
                fontSize: "40px",
              }}
            >
              {translations?.sendEnquiry}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontSize: "16px",
                width: "61%",
                marginTop: 16,
                fontFamily: "MabryProLight",
              }}
            >
              {ReactHtmlParser(queryDescription)}
            </Typography>
            <p
              style={{
                fontSize: "13px",
                color: "white",
                margin: 0,
                fontFamily: "MabryProLight",
              }}
            >
              Topic
            </p>
            <Select
              style={{ marginTop: 20, color: "white" }}
              id="standard-select-currency"
              select
              label={translations.topic}
              className="input-select-field"
              value=""
              onChange={handleFormData}
              name="topic"
              inputProps={{
                className: `${classes.input} ${classes.underline} ${classes.error}`,
                root: classes.underline,
                error: classes.error,

                // classes: {
                // },
              }}
              inputLabelProps={{
                className: classes.labelColor,
                classes: {
                  root: classes.label,
                  focused: classes.focusedLabel,
                  error: classes.erroredLabel,
                },
                shrink: true,
                fontFamily: "MabryProLight",
              }}
              margin="normal"
              displayEmpty
            >
              {console.log("formData.topic>>>", formData)}
              <MenuItem key="" value="" disabled>
                Select your Topic
              </MenuItem>
              {queryTopics?.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            <TextField
              id="standard-full-width"
              label={translations?.fullName}
              style={{ width: "100%", marginTop: 20 }}
              placeholder={translations?.typeName}
              value={formData.fullName}
              name="fullName"
              onChange={handleFormData}
              margin="normal"
              InputProps={{
                className: classes.input,
                classes: {
                  root: classes.underline,
                  error: classes.error,
                },
              }}
              InputLabelProps={{
                className: classes.labelColor,

                classes: {
                  root: classes.label,
                  focused: classes.focusedLabel,
                  error: classes.erroredLabel,
                },
                shrink: true,
                fontFamily: "MabryProLight",
                color: "white",
              }}
            />
            <TextField
              error={!isEmailValid}
              helperText={emailError}
              id="standard-full-width"
              label={translations?.emailAddress}
              value={formData.email}
              style={{ width: "100%", marginTop: 20 }}
              placeholder={translations?.typeEmail}
              name="email"
              onChange={e => {
                if (
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                    e.target.value
                  )
                ) {
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                  setIsEmailValid(true)
                  setEmailError("")
                } else {
                  setFormData({ ...formData, [e.target.name]: e.target.value })
                  setIsEmailValid(false)
                  setEmailError("Incorrect Email")
                }
              }}
              margin="normal"
              InputProps={{
                className: classes.input,

                classes: {
                  root: classes.underline,
                  error: classes.error,
                },
              }}
              InputLabelProps={{
                className: classes.labelColor,

                classes: {
                  root: classes.label,
                  focused: classes.focusedLabel,
                  error: classes.erroredLabel,
                },
                shrink: true,
                fontFamily: "MabryProLight",
              }}
            />
            <TextField
              id="standard-full-width"
              label={translations?.message}
              name="message"
              onChange={handleFormData}
              value={formData.message}
              style={{ width: "100%", marginTop: 20 }}
              placeholder={translations?.message}
              margin="normal"
              InputProps={{
                className: classes.input,

                classes: {
                  root: classes.underline,
                  error: classes.error,
                },
              }}
              InputLabelProps={{
                className: classes.labelColor,

                classes: {
                  root: classes.label,
                  focused: classes.focusedLabel,
                  error: classes.erroredLabel,
                },
                shrink: true,
                fontFamily: "MabryProLight",
              }}
            />
            <Button
              variant="contained"
              color="#FAD578"
              type="submit"
              style={{
                width: 200,
                marginTop: 30,
                backgroundColor: "#FAD578",
                fontSize: "16px",
                fontFamily: "MabryProRegular",
                textTransform: "none",
              }}
              endIcon={
                <div className={classes.arrow}>
                  <ArrowForward />
                </div>
              }
            >
              {translations.sendMessage}
            </Button>

            <Card
              className={classes.card}
              style={{
                marginTop: 80,
                backgroundColor: "#1A1B1D",
                width: "100%",
              }}
            >
              <CardContent>
                <Typography
                  style={{
                    color: "white",
                    marginLeft: 20,
                    fontFamily: "MabryProBold",
                    fontSize: "20px",
                  }}
                >
                  {hateContactFormTitle}
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    fontSize: "16px",
                    marginLeft: 20,
                    fontFamily: "MabryProLight",
                  }}
                >
                  {hateContactFormSubTitle}
                </Typography>
                <List
                  className={classes.root}
                  style={{ backgroundColor: "#1A1B1D" }}
                >
                  {hateContactFormLogos?.map(({ fluid }, key) => {
                    return (
                      <React.Fragment>
                        <ListItem key={key} role={undefined}>
                          <Img
                            style={{ width: 30, height: 30, marginRight: 5 }}
                            fluid={fluid}
                          />
                          <ListItemText id="abc">
                            <Typography
                              style={{
                                fontSize: "16px",
                                color: "white",
                                fontFamily: "MabryProBold",
                                textTransform: "lowercase",
                              }}
                            >
                              {hateContactFormLogosId[key]}
                            </Typography>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="comments"
                              style={{ color: "white" }}
                            >
                              <div className={classes.arrow}>
                                <ArrowForward />
                              </div>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {key !== hateContactFormLogosId.length - 1 && (
                          <Divider
                            style={{ backgroundColor: "gray", marginLeft: 0 }}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={10} sm={11} md={7}>
            {!!image.fluid && (
              <Img
                style={{ width: "100%", height: "100%" }}
                fluid={image.fluid}
              />
            )}
          </Grid>
        </Grid>
      </div>
      <ThemedDivider />
    </form>
  )
}

export default withWidth()(SendEquiry)
