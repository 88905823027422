import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import React from "react"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"

const InvestFooter = props => {
  const translations = useSelector(state => state?.locale?.localeObj?.pageData)
  const { width } = props
  const isSmall = width === "xs" || width === "sm"
  return (
    <div>
      <div
        style={
          isSmall
            ? { padding: 20 }
            : {
                paddingLeft: 58,
                paddingRight: 58,
              }
        }
      >
        <Grid container spacing={4}>
          <Grid item lg={2} xs={10} sm={4} md={6}>
            <Typography
              style={{ color: "white", fontWeight: "bold", fontSize: 22 }}
            >
              {translations?.header?.home}
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "white",
                fontFamily: "MabryProLight",
              }}
            >
              {translations?.copyright}
            </Typography>
          </Grid>
          <Grid item lg={2} xs={10} sm={4} md={6}>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProLight",
                fontSize: "12px",
              }}
            >
              {translations?.travel}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 16,
              }}
            >
              {translations?.privateTour}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.createCustomTour}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.dmcAndIncoming}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.iAmTourLeader}
            </Typography>
          </Grid>
          <Grid item lg={2} xs={10} sm={4} md={6}>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProLight",
                fontSize: "12px",
              }}
            >
              {translations?.cities}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 16,
              }}
            >
              Porto
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              Lisbon
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              Coimbra
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              Braga
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              Madrid
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              Barcelona
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              Sevilla
            </Typography>
          </Grid>
          <Grid item lg={2} xs={10} sm={4} md={6}>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProLight",
                fontSize: "12px",
              }}
            >
              {translations?.invest}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 16,
              }}
            >
              {translations?.services}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                marginTop: 10,
              }}
            >
              {translations?.investPages?.goldenVisa}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                marginTop: 10,
              }}
            >
              {translations?.investPages?.whyPortugal}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.opportunities}
            </Typography>
          </Grid>
          <Grid item lg={2} xs={10} sm={4} md={6}>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProLight",
                fontSize: "12px",
                marginBottom: 16,
              }}
            >
              {translations?.header?.menu?.invest?.toUpperCase()}
            </Typography>
            <Typography
              component={Link}
              to="/Careers"
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                textDecoration: "none",
                fontSize: "16px",
              }}
            >
              {translations?.careers}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.blogs}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.header?.contact}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.privacyPolicy}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.termsAndConditions}
            </Typography>
          </Grid>
          <Grid item lg={2} xs={10} sm={4} md={6}>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProLight",
                fontSize: "12px",
                marginBottom: 16,
              }}
            >
              {translations?.social}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
              }}
            >
              {translations?.facebook}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 16,
              }}
            >
              {translations?.instagram}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.tripAdvisor}
            </Typography>
            <Typography
              style={{
                color: "white",
                fontFamily: "MabryProRegular",
                fontSize: "16px",
                marginTop: 10,
              }}
            >
              {translations?.youtube}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default InvestFooter
